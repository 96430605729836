<template>
    <div>
        <div class="container">
            <header>
                <div class="header-box">
                    <div class="header-content">
                        <div class="logo">
                            <img src="../../assets/img/logo.jpg" alt="">
                        </div>
                        <div class="right-tiem">
                            <div class="menu">
                                <ul>
                                    <!-- <li>マキマキとは</li>
                                    <li>お問い合わせ</li>
                                    <li>よくあるご質問</li> -->
                                </ul>
                            </div>
                            <div class="search">
                                <!-- <i class="el-icon-search"></i> -->
                            </div>
                            <div class="user-portrait">
                                <el-dropdown>
                                    <div class="img-box">
                                        <img v-if="userInfo.pic" :src="pic + userInfo.pic" alt="">
                                    </div>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item divided @click.native="logout">
                                            <span style="display:block;">サインアウト</span>
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div class="main">
                <div class="main-box">
                    <div class="left-router">
                        <div class="left-box">
                            <div>
                                <div class="user-portrait">
                                    <div class="box">
                                        <div class="img-box">
                                            <img v-if="userInfo.pic" :src="pic + userInfo.pic" alt="">
                                        </div>

                                    </div>
                                    <p>{{ userInfo.nickname }}</p>
                                </div>
                            </div>
                            <div>
                                <ul v-for="(router, index) in router_"
                                        :key="index" :class="{ 'active': index == isActive }">
                                    <li class="menus" @click="tabRouter(router, index)" v-if="router.status">
                                        <span>{{ router.title }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="right-view">
                        <transition mode="out-in">
                            <router-view></router-view>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import utils from '@/assets/js/utils'
import service from '@/service'
export default {
    name: `personalCenter`,
    data() {
        return {
            pic: "/userupload/",
            routers: [
                {
                    title: "マイページ",  //个人首页
                    path: "/personal-index",
                    status: true,
                },
                {
                    title: "プロジェクト管理",  //项目管理
                    path: "/personal-project",
                    status: true,
                },
                // {
                //     title: "収益管理", //收益管理
                //     path: "/profit-manage",
                //     status: true,
                // },
                {
                    title: "友だちリスト", // 好友管理
                    path: "/friend-manage",
                    status: true,
                },
                {
                    title: "個人情報", //资料管理
                    path: "/personal-data",
                    status: true,
                },
                {
                    title: "プロジェクト検索",//项目搜索
                    path: "/personal-project-search",
                    status: false,
                },
                {
                    title: "ユーザ検索",
                    path: "/search-original",
                    status: false,
                },
                {
                    title: "案件検索",
                    path: "/personal-project-ajsearch",
                    status: false,
                },
            ],
            rootRouter: '/personal-center',
            isActive: '0',
            userInfo: {},
            url: utils.getStorage("userInfo").pic,
            role: [],
            roleArr:[],
            loginattr: 1
        }
    },
    created() {
        this.getLoginInfo();
    },
    computed: {
        // iscreatRole() {
        //     if(this.loginattr == 1) {
        //         this.routers[6].title = "123123"
        //         this.$set(this.routers[6].title,"123132")
        //     }
        // }
        router_() {
            return this.routers.filter(item => item.status)
        }
    },
    mounted() {
        if (this.$route.query.id) {
            this.isActive = this.$route.query.id;
        } else {
            this.isActive = "0";
        }

    },
    watch: {
        $route(from) {
            this.isActive = from.query.id;

        },
        userInfo: {
            handler(val,old) {
                let roleArr = val.role.split(",")
                if(roleArr.indexOf("1") > -1) {
                   this.routers[5].status = true
                   this.routers[5].title = '編集者検索'
                }
                 if(roleArr.indexOf("2") > -1) {
                   this.routers[5].status = true
                   this.routers[5].title = '原作者検索'
                }
                if(roleArr.indexOf("5") > -1 || roleArr.indexOf("6") > -1) {
                   this.routers[6].status = true
                }
                if(roleArr.indexOf("3") > -1 || roleArr.indexOf("4") > -1 || roleArr.indexOf("7") > -1) {
                   this.routers[4].status = true
                }
                // if(roleArr.indexOf("3") < 0) {
                //     this.routers.filter(item => item.path != '/personal-project-search')
                // }
                // if(val.role.indexOf("4") < 0 || val.role.indexOf("7") < 0) {
                //     this.routers.filter(item => item.path != '/search-original')
                //     this.routers.filter(item => item.path != '/personal-project-ajsearch')
                // }
            },
            deep:true
        }
    },
    methods: {
        logout() {
            service.home.userLogout().then(res => {
                if (res.status === 200) {
                    this.$message.success("サインアウトしました");
                    this.$router.push('/')
                }

            }).catch(err => {
                console.log(err)
            })
        },
        tabRouter(item, index) {
            this.$router.push(`${this.rootRouter + item.path}?id=${index}`)
            this.isActive = index;
        },
        getLoginInfo() {
            service.personal.getLoginInfo().then(res => {
                let data = res.data;
                this.userInfo = data.data;
                this.role = this.userInfo.role.split(",");
                utils.setStorage("userInfo", data);
                this.loginattr = this.userInfo.loginattr
            })
        }
    }
}

</script>
<style lang="scss" scoped>
.container {
    width: 100%;
    height: 100vh;
    background: #f4f4f4;
    overflow: hidden;

    header {
        width: 100%;
        height: 106px;
        background-color: #fff;
        border-bottom: 4px solid #F03747;

        .header-box {
            width: 75%;
            height: 100%;
            margin: 0 auto;

            .header-content {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;

                .logo {
                    color: #000;
                    font-size: 42px;
                    // flex: 1;
                    img {
                        width: 8%;
                    }
                }

                .right-tiem {
                    .img-box {
                        width: 60px;
                        height: 60px;
                        border-radius: 100%;
                        display: flex;
                        justify-content: center;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 100%;
                        }
                    }

                    display: flex;
                    align-items: center;

                    .menu {
                        ul {
                            display: flex;

                            li {
                                font-size: 20px;
                                padding: 0 35px;
                                color: #333;
                                cursor: pointer;
                            }
                        }
                    }

                    .search {
                        margin: 0 45px 0 20px;

                        i {
                            font-size: 20px;
                            color: #666666;
                        }
                    }
                }
            }
        }
    }

    .main {
        width: 100%;
        height: 100%;
        margin-top: 10px;

        .main-box {
            position: relative;
            width: 75%;
            height: 100%;
            margin: 0 auto;

            .left-router {
                width: 250px;
                background-color: #fff;
                position: absolute;
                top: 0px;
                left: 0;
                bottom: 0;

                .left-box {
                    padding: 20px 0;

                    >div:nth-child(1) {
                        margin-top: 20px;

                        .user-portrait {
                            .box {
                                display: flex;
                                justify-content: center;
                            }

                            .img-box {
                                width: 80px;
                                height: 80px;
                                border-radius: 100%;
                                display: flex;
                                justify-content: center;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 100%;
                                }
                            }

                            text-align: center;

                            p {
                                font-size: 24px;
                                color: #333;
                                margin-top: 10px;
                            }
                        }
                    }

                    >div:nth-child(2) {
                        margin-top: 30px;

                        ul {
                            padding: 0 25px;
                            li {
                                height: 45px;
                                line-height: 45px;
                                cursor: pointer;
                                font-size: 16px;
                                padding: 0 20px;
                                position: relative;
                                background-color: #364e61;
                                margin-top: 14px;
                                border-radius: 25px;
                                color: #fff;
                            }

                            // li:before {
                            //     content: "";
                            //     height: 30px;
                            //     width: 4px;
                            //     background: #F03747;
                            //     display: none;
                            //     position: absolute;
                            //     left: 0;
                            //     top: 8px;
                            // }

                            li.active {
                                display: block;
                                 background-color: green;
                            }
                            li:hover {
                                background-color: #1875d2;
                            }
                        }
                    }
                }
            }

            .right-view {
                position: absolute;
                background-color: #fff;
                top: 0px;
                left: 260px;
                bottom: 0;
                right: 0;
                overflow-y: scroll;
                padding-bottom: 100px;
            }

            .right-view::-webkit-scrollbar {
                width: 5px;
            }

            .right-view::-webkit-scrollbar-track {
                background-color: #F4F8FB;
                border-radius: 10px;
            }

            .right-view::-webkit-scrollbar-thumb {
                background-color: #ccc;
                border-radius: 10px;
            }
        }
    }
}
</style>